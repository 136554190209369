<template>
  <div v-frag>
    <div id="form-header" class="inner-header">
      <h2>
        {{ txtHeader }}
        <span id="required-note"
          >I campi contrassegnati da un asterisco sono obbligatori</span
        >
      </h2>
      <button
        id="form-cliente-back"
        @click="$emit('cliente', { op: 'cancelEdit' })"
      >
        <font-awesome-icon icon="arrow-left" color="white" /> Indietro
      </button>
    </div>
    <FormSchemaNative
      v-noac
      ref="formCliente"
      id="form-cliente"
      name="form-cliente"
      :schema="SchemaCliente"
      :key="cliente.id"
      v-model="cliente"
    >
      <fieldset>
        <FormSchemaNative
          ref="formIndirizzo"
          id="form-indirizzo"
          :schema="SchemaIndirizzoCliente"
          v-model="sede"
        />
      </fieldset>
    </FormSchemaNative>
    <div id="conferma-actions">
      <span class="avviso-bottom"></span>
      <button
        id="conferma-dati-cliente"
        @click.prevent="$emit('cliente', { op: 'save', cli: clienteDaSalvare })"
      >
        <font-awesome-icon icon="save" color="white" /> Salva dati
      </button>
    </div>
    <div style="clear: both">&nbsp;</div>
  </div>
</template>

<script>
/* eslint-disable */
import { Forms } from "mida4-web-app-utils";
import {
  hasPIVA,
  getClienteRequiredFields,
} from "mida4-fattura-rapida-spese-sanitarie/doc";
import NazioniIndirizzo from "@/entities/enums/nazioniIndirizzo";
import SchemaCliente from "@/entities/schemas/cliente.json";
import SchemaIndirizzo from "@/entities/schemas/indirizzo.json";

import FormSchemaNative from "@formschema/native";

const SchemaIndirizzoCliente = SchemaIndirizzo;
SchemaIndirizzoCliente.properties.nazione.enum = NazioniIndirizzo.getEnum();
["attrs", "minLength", "maxLength"].forEach(
  (t) => delete SchemaIndirizzoCliente.properties.nazione[t]
);

import { mapActions, mapState } from "vuex";

const NAME = "FormClienteWebFe";
export default {
  props: ["clienteAttivo"],
  components: {
    FormSchemaNative,
  },
  data() {
    return {
      NAME,
      SchemaCliente,
      SchemaIndirizzoCliente,
      cliente: null,
      sede: null,
      txtHeader: null,
    };
  },
  computed: {
    ...mapState("clienti", {
      requiredFields: (s) => s.required,
    }),
    _txtHeader() {
      let nome = this.cliente?.nome + " " ?? "";
      if (nome !== "null") {
        nome = "";
      }
      return this.cliente.id
        ? `${nome}${this.cliente.cognomeDenom} - ${this.cliente.codiceFiscale}`
        : "Nuovo cliente";
    },
    clienteDaSalvare() {
      return {
        ...this.cliente,
        sede: {
          ...this.sede,
        },
      };
    },
  },
  created() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.SchemaCliente.properties.paesePartitaIVA.title = "Naz. P. IVA";
    }
    this.cliente = this.clienteAttivo;
    this.sede = this.clienteAttivo.sede;
    this.txtHeader = this._txtHeader;
  },
  mounted() {
    this.$nextTick(() => {
      /// codice duplicato: cercare componente ClienteDettaglioDocumentoSpSa
      if (!this.sede.nazione) {
        this.sede.nazione = "IT";
      }
      Forms.riordinaFormClienteDocumento(this, this.DEFAULTS.DESTINAZIONE);
      this.updateRequired(true);
      this.listenFormCliente();
    });
  },
  methods: {
    ...mapActions({
      setClienteRequired: "clienti/setRequired",
    }),

    updateRequired(highlight = true) {
      const flatten = (arr) => arr.reduce((a, i) => a.concat(i), []);

      const isPersGiuridica =
        this.cliente.codiceFiscale.length === 11 || hasPIVA(this.cliente);

      const req = [
        getClienteRequiredFields(
          isPersGiuridica,
          SchemaCliente,
          this.sede.nazione,
          this.cliente.codiceFiscale
        ),
        SchemaIndirizzo.required,
      ];

      this.setClienteRequired(req);
      if (highlight) {
        Forms.highlightRequired(flatten(req));
      }
    },

    setPaesePIVA(evt) {
      this.cliente.paesePartitaIVA = evt.target.value;
      document.querySelector("input[name=paesePartitaIVA]").value =
        evt.target.value;
      this.updateRequired(true);
    },

    listenFormCliente(isAdd = true) {
      let fn = isAdd ? "addEventListener" : "removeEventListener";
      ["codiceFiscale", "provincia", "comune", "paesePartitaIVA"].forEach(
        (fName) =>
          document
            .querySelector(`input[name=${fName}]`)
            [fn]("input", Forms.evTargetToUpper)
      );
      document
        .querySelector("select[name=nazione]")
        .addEventListener("change", this.setPaesePIVA);
      // document.querySelector('select[name=nazione]').addEventListener("change", this.updateHighlightRequired)
      document.querySelectorAll("[name=form-cliente] input").forEach((el) => {
        el[fn]("change", this.updateRequired);
        el[fn]("input", this.updateRequired);
        //el[fn]("keydown", this.keepFocusOnMe);
        //el[fn]("focus", this.resetFocus)
        //el[fn]("blur", emitCliente)
      });
    },
  },

  watch: {
    cliente: {
      deep: true,
      handler(cli) {
        this.$nextTick(() => {
          this.updateRequired(false);
          this.$emit("cliente", { op: "update", cli });
        });
      },
    },
  },
};
</script>

<style lang="scss">
#conferma-actions {
  height: 1.5rem;
  #conferma-dati-cliente {
    position: absolute;
    right: 2rem;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
}
label.required::after {
  content: "*";
}
#required-note {
  font-size: 0.9rem;
  font-weight: normal;
  margin-top: 0.5rem;
  display: block;
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
  section#clienti {
    #form-cliente-back {
      transform: scale(0.8);
    }
  }
}

@media screen and (max-width: 426px) {
  section#clienti {
    #form-cliente-back {
      font-size: 2.5vw;
    }
  }
}

@media screen and (max-width: 1023px) {
  section#clienti {
    #form-cliente-back {
      // font-size: 2.5vw;
      padding: 1vw 2vw;
      height: unset;
    }
    #form-cliente {
      // select {
      //   font-size: 2.5vw !important;
      // }
      fieldset {
        margin: 0;
        padding: 0;
        border: none;
        div#form-indirizzo {
          h1 {
            margin: 0;
          }
        }
      }
    }
    #conferma-actions {
      #conferma-dati-cliente {
        right: 0.5rem;
        font-size: 14px;
        width: 140px;
        height: 30px;
      }
    }
  }
}

@media screen and (min-width: 650px) and (max-width: 1023px) {
  section#clienti {
    #conferma-actions {
      #conferma-dati-cliente {
        right: 1.5rem !important;
      }
    }
  }
}
</style>

