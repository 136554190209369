<template>
  <div id="filtro">
    <div id="help">
      Per filtrare l'elenco inserire nella maschera le iniziali del Codice
      Fiscale o, in alternativa, le iniziali del cognome, e facoltativamente uno
      spazio seguito dalle iniziali del nome.
    </div>
    <input
      type="text"
      name="filtra-cliente"
      placeholder="Minimo tre caratteri"
      @input="filtra"
      :value="filtro"
    />
  </div>
</template>

<script>
  const NAME = "FiltroElencoClienti";
  import { mapState, mapActions } from "vuex";
  export default {
    name: NAME,
    mounted() {
      this.$nextTick(() => {
        const inp = document.querySelector("[name=filtra-cliente]");
        inp.focus();
        if (this.filtro) {
          const pos = inp.value.length;
          inp.setSelectionRange(pos, pos);
        }
      });
    },
    computed: {
      ...mapState("clienti", {
        filtro: (s) => s.filter,
      }),
    },
    methods: {
      ...mapActions({
        filtra: "clienti/applicaFiltro",
      }),
    },
  };
</script>

<style lang="scss">
  #filtro {
    input {
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      margin-bottom: 1rem;
    }
  }
  #help {
    font-size: 1rem;
    margin-bottom: 0.6rem;
  }
  // @media screen and (max-width: 1024px) {
  //   #filtro {
  //     input {
  //       font-size: 3vw;
  //     }
  //   }
  // }
  /* On screens that are 992px or less, set the background color to blue */
  @media screen and (max-width: 600px) {
  }
</style>

