<template>
  <LayoutSpSa>
    <section id="rinnovo-wrapper">
      <div v-if="startupError === null">
        <a href="https://www.stripe.com" id="link-stripe" target="_blank">
          <img src="@/assets/stripe.svg" alt="" />
        </a>
        <MessaggioPagamentoSpSa
          :key="importo"
          :importo="importo"
          :importoBase="importoBase"
          :importoPerFattura="importoPerFattura"
          :dataScadenzaRinnovo="dataScadenzaRinnovo"
        />
        <h3>
          <label id="card-label">Dati carta di credito</label>
          <div id="card-brands">
            <img
              src="@/assets/3_Card_color_horizontal.png"
              alt="Carte supportate: AmEx - Mastercard - Visa"
            />
          </div>
        </h3>
        <div id="card"></div>
        <CondizioniContrattualiSpSa :isFormUtente="false" />
        <button id="custom-button" class="disabled" @click="rinnova">
          <font-awesome-icon id="lock" icon="lock" />Procedi
        </button>
      </div>
      <div v-else-if="startupError !== null" id="errore-stripe">
        <p v-html="startupError.message"></p>
        <router-link :to="{ name: 'Home' }">Indietro</router-link>
      </div>
      <div v-else-if="pollActive">
        In attesa di conferma pagamento. Verrete reindirizzati non appena il
        procedimento sarà completato
      </div>
    </section>
  </LayoutSpSa>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { R } from "mida4-web-app-utils";
import { StatusCodes } from "http-status-codes";
import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import CondizioniContrattualiSpSa from "@/components/_common/CondizioniContrattualiSpSa";
import MessaggioPagamentoSpSa from "@/components/RinnovoSpSa/MessaggioPagamentoSpSa";
import { DateDefaults } from "mida4-web-app-utils";
import { format } from "date-fns";

import { loadStripe } from "@stripe/stripe-js/pure";

let startupError = null;

const NAME = "RinnovoSpSa";
export default {
  name: NAME,
  components: {
    LayoutSpSa,
    MessaggioPagamentoSpSa,
    CondizioniContrattualiSpSa,
  },
  data() {
    return {
      importo: null,
      importoBase: null,
      importoPerFattura: null,
      dataScadenzaRinnovo: null,
      piClientSecret: null,
      card: null,
      pagamentoRegistrato: false,
      startupError: null,
      pollActive: false,
      stripe: null,
    };
  },
  computed: {
    ...mapGetters({
      userData: "user/getData",
      scaduto: "user/scaduto",
    }),
  },

  async mounted() {
    try {
      this.toggleLoader(true);
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PK, {
        locale: "it",
      });

      const res = await this.$api.impostaRinnovo();
      if (res.status !== StatusCodes.OK) {
        // this.$log.debug('ora lancio')
        throw new Error("Errore Stripe");
      }
      const {
        clientSecret,
        importoBase,
        importoPerFattura,
        dataScadenzaRinnovo,
      } = await res.json();

      const resultPaymentIntent = await this.stripe.retrievePaymentIntent(
        clientSecret
      );

      if (!R.isNil(resultPaymentIntent.error)) {
        this.showError({
          title: "Errore Stripe",
          message: resultPaymentIntent.error.message,
        });
        return;
      } else {
        this.importo = resultPaymentIntent.paymentIntent.amount;
        this.importoBase = importoBase;
        this.importoPerFattura = importoPerFattura;
        this.dataScadenzaRinnovo = format(
          new Date(dataScadenzaRinnovo),
          DateDefaults.formats.ONLY_DATE
        );

        this.piClientSecret = clientSecret;
      }

      if (!R.isNil(startupError)) {
        this.toggleLoader(false);
        this.showError(startupError);
      }
      // Style Object documentation: https://stripe.com/docs/js/appendix/style
      const style = {
        base: {
          color: "black",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "1.3rem",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      };
      this.card = this.stripe.elements().create("card", { style });
      this.card.mount("#card");
    } catch (e) {
      console.error("🚀 ~ mounted ~ e:", e);
      this.toggleLoader(false);
      this.startupError = {
        title: "Errore di rete",
        message:
          "Non è stato possibile contattare il server. <br>Controllare la connessione a internet.<br>Se il problema persiste si prega di contattare l'assistenza.",
      };
    } finally {
      /// imbroglio per evitare di vedere le componenti che si spostano!
      setTimeout(() => {
        this.toggleLoader(false);
      }, 300);
    }
  },

  beforeDestroy() {
    this.card.destroy();
  },

  methods: {
    ...mapActions({
      refreshDatiPagamento: "user/refreshDatiPagamento",
    }),
    richiediAccettazione() {
      const rimuoviEvidenziazione = () =>
        document
          .querySelector("#accetta-condizioni")
          .classList.remove("evidenzia");
      document.querySelector("#accetta-condizioni").classList.add("evidenzia");
      document
        .querySelectorAll("#condizioni-contrattuali [type=checkbox]")
        .forEach((el) =>
          el.addEventListener(
            "input",
            () =>
              this.condizioniContrattualiAccettate() && rimuoviEvidenziazione(),
            { once: true }
          )
        );
      return false; // vedi sotto
    },
    condizioniContrattualiAccettate() {
      return (
        ["contrattuali", "vessatorie", "privacy"].every(
          (check) =>
            document.querySelector(`#condizioni-contrattuali [name=${check}]`)
              .checked
        ) || this.richiediAccettazione()
      );
    },
    async rinnova() {
      if (!this.condizioniContrattualiAccettate()) {
        return false;
      }

      this.toggleLoader(true);

      const { error } = await this.$stripe.confirmCardPayment(
        this.piClientSecret,
        {
          payment_method: {
            card: this.card,
            billing_details: {
              name: `${this.userData.cognomeDenom}${
                this.userData.nome ? " " + this.userData.nome : ""
              }`,
            },
          },
        }
      );

      this.toggleLoader(false);

      if (!R.isNil(error)) {
        this.showError({
          title: "Pagamento Fallito",
          message: error.message,
        });
        this.card.clear();
      } else {
        this.showSuccess();
        this.pollRinnovoRicevuto();
      }
      // Log.JSONstr(paymentIntent)
      // Log.JSONstr(error)
    },
    async pollRinnovoRicevuto() {
      try {
        this.pollActive = true;
        this.pagamentoRegistrato = await this.refreshDatiPagamento();
        if (!this.pagamentoRegistrato) {
          setTimeout(this.pollRinnovoRicevuto, 1000);
        }
      } catch (error) {
        this.showError({
          title: "Errore verifica ricezione pagamento",
          message:
            "Non è stato possibile contattare il server per verificare la ricezione del pagamento. Provare più tardi.",
        });
      }
    },
  },
  watch: {
    pagamentoRegistrato(val) {
      if (val === true) {
        const pulsanteNotifica = document.querySelector(
          "button.swal-button--confirm"
        );
        if (!R.isNil(pulsanteNotifica)) {
          pulsanteNotifica.click();
        }
        setTimeout(() => {
          location.href = location.pathname;
        }, 500);
      }
    },
  },
  notifications: {
    showSuccess: {
      title: "Il pagamento è andato a buon fine",
      message:
        "Il servizio è stato rinnovato, attendere qualche istante perchè i nostri sistemi processino il pagamento. Verrete reindirizzati non appena il pagamento verrà comunicato ai server Mida4",
      type: "success",
    },
    showError: {
      title: "Errore pagamento",
      message: "Si è verificato un errore nel pagamento",
      type: "error",
    },
  },
};
</script>

<style src="@/styles/custom/views/_rinnovo.scss" lang="scss"></style>

