<template>
  <button
    id="elimina"
    v-if="state === BtnState.IDLE"
    v-tooltip="'Elimina'"
    @click.stop="activate"
  >
    <font-awesome-icon
      style="color: white"
      icon="trash"
    />
  </button>
  <button id="elimina" v-else class="danger" @click.stop="confirm">
    CONFERMA({{ timer }})
  </button>
</template>

<script>
  const NAME = "PulsanteEliminaSpSa";

  const BtnState = {
    IDLE: 0,
    ACTIVE: 1,
  };

  export default {
    name: NAME,
    data() {
      return {
        BtnState,
        state: BtnState.IDLE,
        timer: 3,
      };
    },
    methods: {
      countdown() {
        // console.log("chiamato countdown");
        if (this.timer === 0) {
          this.state = BtnState.IDLE;
          this.timer = 3;
        } else {
          setTimeout(() => {
            this.timer -= 1;
            this.countdown();
          }, 1000);
        }
      },
      activate() {
        this.state = BtnState.ACTIVE;
        this.countdown();
      },
      confirm() {
        this.state = BtnState.IDLE;
        this.$emit("confirm");
      },
    },
  };
</script>

<style lang="scss">
  button#elimina {
    // width: 9rem;
    // display: flex;
    justify-content: center;
    &.danger {
      background: darkred !important;
    }
    // svg {
    //   margin-right: 1rem;
    // }
  }

  @media screen and (max-width: 1024px) {
    button#elimina {
      // width: 20vw;
      font-size: 14px !important;
      display: flex;
      justify-content: center;
      padding: 1.5vw 2vw;
      &.danger {
        background: darkred !important;
      }
      // svg {
      //   margin-right: 1rem;
      // }
    }
  }
  /* On screens that are 992px or less, set the background color to blue */
  @media screen and (max-width: 600px) {
  }
</style>

