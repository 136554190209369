<template>
  <div v-frag>
    <div id="elenco-header" class="inner-header">
      <h2>Elenco clienti</h2>
      <button id="nuovo-cliente" @click="$emit('cliente', { op: 'create' })">
        <font-awesome-icon icon="plus" color="white" /> Nuovo Cliente
      </button>
    </div>
    <!-- <filtro-elenco-clienti-web-fe /> -->
    <table id="elenco-clienti">
      <HeadersTabelleSpSa
        :key="JSON.stringify(headers)"
        :headers="headers"
        :isDashboard="false"
        @orderBy="$emit('order', { op: 'by', ...$event })"
        @resetOrder="$emit('order', { op: 'reset' })"
      />
      <tbody>
        <tr
          v-for="c in clienti"
          :key="c.id"
          @click="$emit('cliente', { op: 'edit', cli: c })"
          v-tooltip="'Click per modificare'"
        >
          <td>{{ c.cognomeDenom }}</td>
          <td>{{ c.nome }}</td>
          <td>{{ c.codiceFiscale }}</td>
          <td v-if="isMobile">{{ c.sede.comune }}</td>
          <td v-if="isMobile">{{ c.telefono }}</td>
          <td v-if="isMobile">{{ c.email }}</td>
          <td class="action nuova-fattura" v-if="!scaduto && isMobile">
            <button
              v-tooltip="'Predisponi nuova fattura per il cliente'"
              @click.stop="$emit('cliente', { op: 'invoice', cli: c })"
            >
              <font-awesome-icon :icon="'file-alt'" />
              <span>Nuova Fattura</span>
            </button>
          </td>
          <td class="action elimina">
            <PulsanteEliminaSpSa
              @confirm="$emit('cliente', { op: 'delete', cli: c })"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import HeadersTabelleSpSa from "@/components/_common/HeadersTabelleSpSa.vue";
import PulsanteEliminaSpSa from "@/components/ClientiSpSa/PulsanteEliminaSpSa";
import FiltroElencoClientiSpSa from "@/components/ClientiSpSa/FiltroElencoClientiSpSa";

export default {
  name: "ElencoClientiSpSa",

  props: ["headers", "clienti"],

  components: {
    HeadersTabelleSpSa,
    PulsanteEliminaSpSa,
    FiltroElencoClientiSpSa,
    FiltroElencoClientiSpSa,
  },
  methods: {
    orderBy(evt) {
      this.$emit("order", { op: "by", ...evt });
    },
  },
  computed: {
    ...mapGetters({
      scaduto: "user/scaduto",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.width > 1060;
    },
  },
};
</script>

