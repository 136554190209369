<template>
  <LayoutSpSa>
    <section id="clienti" :key="idCliente">
      <FormClienteSpSa
        v-if="clienteAttivo"
        :clienteAttivo="clienteAttivo"
        @cliente="cliente"
      />
      <ElencoClientiSpSa
        v-else
        :key="JSON.stringify(clienti)"
        :headers="cols"
        :clienti="clienti"
        @order="order"
        @cliente="cliente"
      />
    </section>
  </LayoutSpSa>
</template>

<script>
/* eslint-disable */
import { R } from "mida4-web-app-utils";
import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import ElencoClientiSpSa from "@/components/ClientiSpSa/ElencoClientiSpSa.vue";
import FormClienteSpSa from "@/components/ClientiSpSa/FormClienteSpSa.vue";

import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "ClientiSpSa",

  props: ["idCliente"],

  components: {
    LayoutSpSa,
    ElencoClientiSpSa,
    FormClienteSpSa,
  },

  async mounted() {
    this.toggleLoader(true);
    try {
      await this.recuperaElenco();
    } catch (error) {
      this.showError({
        message:
          "Impossibile recuperare l'elenco clienti. Riprovare, se il problema persiste contattare l'assistenza tecnica.",
      });
    }
    this.toggleLoader(false);
  },

  updated() {
    if (R.isNil(this.idCliente) || R.isEmpty(this.idCliente)) {
      this.clienteAttivo = null;
    } else if (this.clienteAttivo === null) {
      if (this.idCliente === "nuovo") {
        this.$nextTick(() => this.createCliente());
      } else {
        const c = this.clienti.find((c) => c.id.toString() === this.idCliente);
        this.clienteAttivo = c;
      }
    }
  },

  computed: {
    ...mapState("clienti", {
      attivo: (s) => s.attivo,
    }),

    ...mapGetters({
      clienti: "clienti/getClienti",
    }),

    clienteAttivo: {
      get() {
        return this.attivo;
      },
      set(c) {
        this.setAttivo(c);
      },
    },

    cols() {
      let headers = [
        {
          value: "Cognome",
          class: "cognomeDenom",
          orderBy: { field: "cognomeDenom", dir: null },
        },
        { value: "Nome", class: "nome" },
        { value: "Codice Fiscale", class: "codiceFiscale" },
        {
          value: "Città",
          class: "citta",
          hide: this.$vuetify.breakpoint.width < 1060,
        },
        {
          value: "Telefono",
          class: "telefono",
          hide: this.$vuetify.breakpoint.width < 1060,
        },
        {
          value: "Email",
          class: "email",
          hide: this.$vuetify.breakpoint.width < 1060,
        },
      ];
      return headers;
    },
  },

  methods: {
    ...mapActions({
      _orderBy: "clienti/orderBy",
      _resetOrder: "clienti/resetOrder",
      setAttivo: "clienti/setAttivo",
      clientiSalvati: "clienti/getClienti",
      recuperaElenco: "clienti/recuperaElenco",
      _deleteCliente: "clienti/deleteCliente",
      _saveCliente: "clienti/saveCliente",
      setCliente: "elaboranda/setCliente",
      setShowCliente: "elaboranda/setShowCliente",
      resetDoc: "elaboranda/resetDoc",
    }),

    order({ op, field, dir }) {
      if (op === "by") {
        this.orderBy({ field, dir });
      } else {
        this.resetOrder();
      }
    },

    cliente({ op, cli }) {
      switch (op) {
        case "create":
          this.createCliente();
          break;
        case "edit":
          this.editCliente(cli);
          break;
        case "delete":
          this.deleteCliente(cli);
          break;
        case "update":
          this.updateCliente(cli);
          break;
        case "save":
          this.saveCliente(cli);
          break;
        case "cancelEdit":
          this.cancelEditCliente();
          break;
        case "invoice":
          this.nuovaFattura(cli);
          break;
      }
    },

    resetOrder() {
      this.headers = [...this.headers].map((h) =>
        h.orderBy ? { ...h, orderBy: { ...h.orderBy, dir: null } } : h
      );
      this._resetOrder();
    },

    updateColHeaders({ field, dir }) {
      this.headers = [...this.headers].map((h) =>
        h.orderBy && h.orderBy.field === field
          ? { ...h, orderBy: { ...h.orderBy, dir } }
          : h
      );
    },

    orderBy({ field, dir }) {
      dir = dir === "desc" ? "asc" : "desc";
      this._orderBy({ field, dir });
      this.updateColHeaders({ field, dir });
    },

    async deleteCliente({ id }) {
      this.toggleLoader(true);
      try {
        await this._deleteCliente(id);
        this.showSuccess({ title: "Cliente eliminato" });
      } catch (error) {
        this.showError({
          html: `Impossibile eliminare il cliente.<br>${error.message}`,
        });
      }
      this.toggleLoader(false);
    },

    editCliente(cli) {
      this.$router.push(`/clienti/${cli.id}`);
      this.clienteAttivo = cli;
    },

    cancelEditCliente() {
      this.$router.push("/clienti");
      this.clienteAttivo = null;
    },

    createCliente() {
      this.$router.push("/clienti/nuovo");
      this.clienteAttivo = { sede: {} };
    },

    updateCliente(c) {
      this.clienteAttivo = c;
    },

    async saveCliente(c) {
      this.toggleLoader(true);
      try {
        await this._saveCliente(c);
        this.clienteAttivo = null;
        this.$router.push("/clienti");
        this.showSuccess();
      } catch (error) {
        const errori = error.message.replaceAll("|", "<br>");
        this.showError({
          html: `Impossibile salvare il cliente.<br>${errori}`,
        });
      }
      this.toggleLoader(false);
    },

    nuovaFattura(c) {
      this.resetDoc();
      this.setCliente(c);
      this.setShowCliente("dati");
      this.$router.push("fattura");
    },
  },

  notifications: {
    showError: {
      title: "Errore",
      message: "Il server ha restituito un errore",
      type: "error",
      html: null,
    },
    showSuccess: {
      title: "Cliente salvato",
      message: "Operazione eseguita correttamente",
      type: "success",
    },
  },
};
</script>

<style lang="scss">
.inner-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  button {
    // height: 2rem;
    // display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    svg {
      margin-right: 1rem;
    }
  }
}
section#clienti {
  @extend .inner-section;
  position: relative;
  padding-top: 1rem;
  margin-top: 2rem;

  #elenco-clienti {
    tr {
      border-bottom: 1px solid #e5ebf3;
      &:hover {
        background: #f0f5ff;
      }
      cursor: pointer;
      td.action {
        padding-right: 0;
        button {
          cursor: pointer;
          margin-bottom: 0;

          background: #0e52ac;
          color: white;
          border-radius: 1.5rem;
          padding: 0.5rem 2.1rem;
          &:hover {
            background-color: #1c4277;
          }
        }
        &.nuova-fattura {
          width: 195px;
          white-space: nowrap;
          svg {
            margin-right: 0.5rem;
          }
        }
        &.elimina {
          width: 95px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  section#clienti {
    #elenco-header {
      // button#nuovo-cliente {
      //   font-size: $pulsanti-mobile !important;
      //   // padding: $padding-pulsanti-mobile;
      //   // height: unset;
      // }
    }
    #elenco-clienti {
      tr {
        &:hover {
        }
        td.action {
          button {
            cursor: pointer;
            margin-bottom: 0;

            background: #0e52ac;
            color: white;
            border-radius: 1.5rem;
            padding: 0.5rem 2.1rem;
            &:hover {
              background-color: #1c4277;
            }
          }
        }
      }
    }
  }
}
/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 600px) {
}
</style>

