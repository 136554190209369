<template>
  <div v-frag>
    <h2 v-html="getTxt()" />
    <p class="info-pagamento" v-if="inProva">
      La tariffa base per l'utilizzo dell'applicazione è di
      <strong>€ {{ formatted(importoBase) }} + IVA</strong>.
    </p>
    <p class="dettagli-info-pagamento" v-if="pagamentoRidotto">
      Al <strong>primo pagamento</strong> l'importo è
      <strong>ridotto in proporzione ai mesi non goduti</strong> per l'anno
      corrente.
    </p>
    <p class="dettagli-info-pagamento" v-else-if="!inProva">
      L'importo, a partire da un
      <strong>minimo di € {{ formatted(importoBase) }} + IVA</strong>, è
      calcolato sulla base del
      <strong>numero di fatture emesse nell'anno precedente</strong>, al prezzo
      di
      <strong>€ {{ formatted(importoPerFattura) }} + IVA per documento</strong>
    </p>
    <p class="info-pagamento">
      A seguito del pagamento il servizio sarà attivo fino al
      <strong>{{ dataScadenzaRinnovo }}</strong
      >.
    </p>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { R } from "mida4-web-app-utils";
  import { formatImporto } from "mida4-web-app-utils/functions";
  import { getMonth } from "date-fns";
  const NAME = "MessaggioPagamentoSpSa";
  export default {
    name: NAME,
    props: [
      "importo",
      "importoBase",
      "importoPerFattura",
      "dataScadenzaRinnovo",
    ],
    methods: {
      formatted(importo) {
        return formatImporto({
          num: importo / 100,
        });
      },
      getTxt() {
        return R.isNil(this.importo)
          ? "Prego attendere ..."
          : `Costo abbonamento annuale: <span class="importo-effettivo">€ ${this.formatted(
              this.importo
            )}<span>`;
      },
    },
    computed: {
      ...mapGetters({
        inProva: "user/inProva",
        dataScadenza: "user/dataScadenza",
      }),
      pagamentoRidotto() {
        return this.inProva && getMonth(new Date()) > 0;
      },
    },
  };
</script>

